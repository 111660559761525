import React from "react";
import "./header.css";
import { Cta } from "./Cta";
import MeImg from "../../assets/Profile2.png";
import { HeaderSocials } from "./HeaderSocials";

export const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <h5>Hello I'm</h5>
        <h1>Agustin Chapuis</h1>
        <h5 className="text-light">Frontend Developer</h5>
        <Cta />
        <a href="#contact" className="scroll__down">
          Scroll Down
        </a>
        <HeaderSocials />

        <div className="me">
          <img src={MeImg} alt="" className="profile-img" />
        </div>
      </div>
    </header>
  );
};
