/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "./navBar.css";
import { AiOutlineHome, AiOutlineUser, AiOutlineMail } from "react-icons/ai";
import { BiBook } from "react-icons/bi";

export const NavBar = () => {
  const [activeNav, setActiveNav] = useState(0);
  return (
    <nav>
      <a
        href="#"
        className={activeNav === 0 ? "active" : ""}
        onClick={() => setActiveNav(0)}
      >
        <AiOutlineHome />
      </a>
      <a
        href="#about"
        className={activeNav === 1 ? "active" : ""}
        onClick={() => setActiveNav(1)}
      >
        <AiOutlineUser />
      </a>
      <a
        href="#experience"
        className={activeNav === 2 ? "active" : ""}
        onClick={() => setActiveNav(2)}
      >
        <BiBook />
      </a>
      <a
        href="#contact"
        className={activeNav === 4 ? "active" : ""}
        onClick={() => setActiveNav(4)}
      >
        <AiOutlineMail />
      </a>
    </nav>
  );
};
