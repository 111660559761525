import React from "react";
import AboutMe from "../../assets/profile.jpeg";
import { FiUser } from "react-icons/fi";
import { VscFolderLibrary } from "react-icons/vsc";
import "./about.css";

export const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={AboutMe} alt="" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FiUser className="about__icon" />
              <h5>Experience</h5>
              <small>3+ Years Working</small>
            </article>
            {/* <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Clients</h5>
              <small>200+ Worldwide</small>
            </article> */}
            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Projects</h5>
              <small>6 Web & Mobile</small>
            </article>
          </div>

          <p>
            This is Agustin. I'm a Mobile and Web Developer. I am very
            passionate about technology and improvement opportunities. I am a
            proactive person, so I am always looking to learn and to improve
            myself taking different training courses. In my free time, I love
            travelling and practicing sports.
          </p>

          <a href="#contact" className="btn btn-primary">
            Lets Talk
          </a>
        </div>
      </div>
    </section>
  );
};
