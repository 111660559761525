import React from "react";
import "./contact.css";
import { AiOutlineMail, AiOutlineWhatsApp } from "react-icons/ai";
import { LiaFacebookMessenger } from "react-icons/lia";

export const Contact = () => {
  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact_option">
            <AiOutlineMail />
            <h4>Email</h4>
            <h5>aguschapuis@gmail.com</h5>
            <a href="mailto:aguschapuis@gmail.com">Send a email</a>
          </article>
          <article className="contact_option">
            <LiaFacebookMessenger />
            <h4>Messenger</h4>
            <h5>Agus Chapuis</h5>
            <a target="_blank" href="https://m.me/aguschapuis" rel="noreferrer">
              Chat with me
            </a>
          </article>
          <article className="contact_option">
            <AiOutlineWhatsApp />
            <h4>Whatsapp</h4>
            <h5>+54 351 7735640</h5>
            <a
              target="_blank"
              href="https://wa.me/+543517735640"
              rel="noreferrer"
            >
              Chat with me
            </a>
          </article>
        </div>
      </div>
    </section>
  );
};
