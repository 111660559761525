import React from "react";
import { Header } from "./components/Header/Header";
import { NavBar } from "./components/NavBar/NavBar";
import { About } from "./components/About/About";
import { Contact } from "./components/Contact/Contact";
import { Experience } from "./components/Experience/Experience";
import { Portfolio } from "./components/Portfolio/Portfolio";

export const App = () => {
  return (
    <>
      <Header />
      <NavBar />
      <About />
      <Experience />
      <Portfolio />
      <Contact />
    </>
  );
};
