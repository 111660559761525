import React from "react";
import Img1 from "../../assets/manacommon.jpeg";
import Img2 from "../../assets/webee.png";
import Img4 from "../../assets/jfs.png";
import Img5 from "../../assets/high.png";
import Img6 from "../../assets/cyberbank.png";
import "./portfolio.css";

export const Portfolio = () => {
  const works = [
    {
      id: 0,
      img: Img1,
      title: "Mana Common App",
      url: "https://manacommon.com/",
    },
    {
      id: 1,
      img: Img5,
      title: "Highway Solutions web",
      url: "https://www.highwaytranslations.com/",
    },
    {
      id: 2,
      img: Img2,
      title: "Webee SmartFactory™",
      url: "https://www.webee.io/?",
    },
    {
      id: 3,
      img: Img6,
      title: "CYBERBANK",
      url: "https://www.technisys.com/es/cyberbank/digital",
    },
    {
      id: 4,
      img: Img4,
      title: "Face Recognition app",
      url: "https://justfoursteps.com/#!/-inicio/?ancla=Bienvenidos",
    },
  ];

  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {works.map((w) => (
          <article className="portfolio__item" key={w.id}>
            <div className="portfolio__item-img">
              <img
                src={w.img}
                alt=""
                className={w.id === 3 ? "business-img-cyber" : "business-img"}
              />
            </div>
            <h4>{w.title}</h4>
            <div className="portfolio__item-cta">
              <a
                href={w.url}
                className="btn btn-primary"
                target="_blank"
                rel="noreferrer"
              >
                Check More
              </a>
            </div>
          </article>
        ))}
      </div>
    </section>
  );
};
