import React from "react";
import { AiFillLinkedin, AiFillGithub, AiFillYoutube } from "react-icons/ai";
export const HeaderSocials = () => {
  return (
    <div className="header__socials">
      <a href="https://linkedin.com" target="_blank" rel="noreferrer">
        <AiFillLinkedin />
      </a>
      <a href="https://github.com" target="_blank" rel="noreferrer">
        <AiFillGithub />
      </a>
      <a href="https://youtube.com" target="_blank" rel="noreferrer">
        <AiFillYoutube />
      </a>
    </div>
  );
};
